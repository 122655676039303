import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AddStudent from './components/AddStudent';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import ViewStudents from './components/ViewStudents';
import AddClass from './components/AddClass';
import UpdateClass from './components/UpdateClass';
import AddSubject from './components/AddSubject';
import UpdateSubject from './components/UpdateSubject';
import UpdateStudent from './components/UpdateStudent';
import SendMessage from './components/SendMessage';
import CheckBoxDemo from './components/CheckBoxDemo';
import ShowMessages from './components/ShowMessages';
import AddAttendance from './components/AddAttendance';
import ShowAttendance from './components/ShowAttendance';
import AddFees from './components/AddFees';
import UpdateFees from './components/UpdateFees';
import AssignFeesToClass from './components/AssignFeesToClass';
import FeesPayment from './components/FeesPayment';
import Login from './components/Login';
import { createContext,useState, useEffect } from 'react';
import WelcomeSchool from './components/WelcomeSchool';
import ViewReceipts from './components/ViewReceipts';
import PrintFeeReceipt from './components/PrintFeeReceipt';
import AddHouse from './components/AddHouse';
import UpdateHouse from './components/UpdateHouse';
import StudentLogin from './components/student/StudentLogin';
import WelcomeStudent from './components/student/WelcomeStudent';
import FeesCard from './components/student/FeesCard';
import FeesReceipts from './components/student/FeesReceipts';
import ImportStudent from './components/ImportStudent';
import GenerateTC from './components/GenerateTC';
import TCRecords from './components/TCRecords';
import PrintTC from './components/PrintTC';
import AddFines from './components/AddFines';
import UpdateFines from './components/UpdateFines';
import SchoolDetails from './components/SchoolDetails';

export const SchoolContext  = createContext();

function App() {
  const [schoolName, setSchoolName] = useState("");
  const [schoolID, setSchoolID] = useState("");
  const [schoolSession, setSchoolSession] = useState("");
  const [schoolUserType, setSchoolUserType] = useState("");

  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState("");


  
  
  return (
    <div className="App">      
      <div className="hold-transition sidebar-mini" >      
        <div className="wrapper">

            {/* <Header></Header>
            <SideMenu></SideMenu> */}

            <SchoolContext.Provider value={{schoolName,setSchoolName,schoolID,setSchoolID,schoolSession,setSchoolSession,schoolUserType,setSchoolUserType, studentName, setStudentName, studentID, setStudentID}}>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="WelcomeSchool" element={<WelcomeSchool />} />

          <Route path="AddClass" element={<AddClass />} />
          <Route exact path="EditClass" element={<UpdateClass />} />
          

          <Route path="AddSubject" element={<AddSubject />} />
          <Route exact path="EditSubject" element={<UpdateSubject />} />

          <Route path="AddHouse" element={<AddHouse />} />
          <Route exact path="EditHouse" element={<UpdateHouse />} />

          <Route path="AddStudent" element={<AddStudent />} />
          <Route path="ImportStudent" element={<ImportStudent />} />

          <Route path="ViewStudents" element={<ViewStudents />} />
          <Route exact path="EditStudent" element={<UpdateStudent />} />

          <Route path="SendMessage" element={<SendMessage />} />
          <Route path="ShowMessages" element={<ShowMessages />} />
          <Route path="CheckBoxDemo" element={<CheckBoxDemo />} />

          <Route path="AddAttendance" element={<AddAttendance />} />
          <Route path="ShowAttendance" element={<ShowAttendance />} />

          <Route path="AddFees" element={<AddFees />} />
          <Route exact path="EditFees" element={<UpdateFees />} />
          <Route path="AssignFeesToClass" element={<AssignFeesToClass />} />
          <Route path="FeesPayment" element={<FeesPayment />} />
          <Route path="ViewReceipts" element={<ViewReceipts />} />
          {/* <Route path="feereceipt/print/:scode" element={<PrintFeeReceipt />} /> */}
          <Route path="PrintReceipt/:scode/:school_id" exact={true} element={<PrintFeeReceipt />} />
          <Route path="AddFines" element={<AddFines />} />
          <Route path="EditFines" element={<UpdateFines />} />

          <Route path="GenerateTC" exact={true} element={<GenerateTC />} />
          <Route path="TCRecords" exact={true} element={<TCRecords />} />
          <Route path="PrintTC/:scode/:school_id" exact={true} element={<PrintTC />} />

          <Route path="SchoolDetails" element={<SchoolDetails />} />

      {/* student routes start */}
          <Route path="student/login" element={<StudentLogin />} />
          <Route path="student/welcome" element={<WelcomeStudent />} />
          <Route path="student/feescard" element={<FeesCard />} />
          <Route path="student/feesreceipts" element={<FeesReceipts />} />
          {/* student routes end */}
        </Routes>
        </SchoolContext.Provider>

       </div>
      </div>  
  </div>
  );
}

export default App;
