import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import qs from 'qs'
import api_url from '../api_url/url_api';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner'
import Header from './Header';
import SideMenu from './SideMenu';
import { SchoolContext } from '../App';



const ShowMessages = () => {

    const { schoolID, schoolSession, schoolUserType } = useContext(SchoolContext);

    const [classes, setClasses] = useState([]);
    const [classId, setClassId] = useState("");

    const [randDep, setRandDep] = useState(0);
    const [messages, setMessages] = useState([]);
    const [messageAll, setmessageAll] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [loading1, setLoading1] = useState(true);

    const getMessageData = async () => {
        try {

            if (schoolID < 1 || schoolID == null) {
                setRandDep(Math.random);
            }
    
            const userData = {
                school_id: schoolID,
                session: schoolSession,
            }

            await  Axios.post(api_url + 'getMessagesForSchool', qs.stringify(userData))
                .then(resp => {
                    setLoading1(false);
                    if (resp.data['message'] == 'OK') {
                        //console.log(resp.data.data);
                        setMessages(resp.data.data);
                        setmessageAll(resp.data.data);
                    }

                });

        }
        catch (err) {
            console.log(err);
        }

    }


    useEffect(() => {
        getMessageData();
        getClasses();
    }, [randDep]);

    const handleOnSearchInput = async text => {
        setSearchQuery(text);
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }

        const filteredData = messageAll.filter(item => {
            if ((item.reg_no.toLowerCase().includes(text.toLowerCase())) || (item.student_name.toLowerCase().includes(text.toLowerCase())) || (item.class_name.toLowerCase().includes(text.toLowerCase()))) {
                return item;
            }
        });


        if (filteredData.length) {
            setMessages([...filteredData]);
        }
        else {
            setMessages([]);
        }
    };


    
    const getClasses = () => {
        if (schoolID < 1 || schoolID == null) {
            setRandDep(Math.random);
        }

        const userData = {
            school_id: schoolID,
            session: schoolSession,
        }
        Axios.post(api_url + 'getClasses', qs.stringify(userData))
            .then((response) => {
                setClasses(response.data.data)
            })
    }


    const classChangeHandler = (event) => {
        let text = event.target.value;
        if (!text.trim()) {
            setSearchQuery('');
            setRandDep(Math.random());
        }


        const filteredData = messageAll.filter(student => {
            if ((student.class_name == text)) {
                return student;
            }
        });


        if (filteredData.length) {
            setMessages([...filteredData]);
        }
        else {
            setMessages([]);
        }

        setClassId(text);

    };


    return (
        <>

            <Header></Header>
            <SideMenu></SideMenu>

            <div className="content-wrapper">
                <ToastContainer />
                <section className="content-header">

                    <div className="header-icon">
                        <i className="pe-7s-box1"></i>
                    </div>

                    <div className="header-title">
                        <h1>Messages</h1>
                        <small>Messsages list</small>
                        <ol className="breadcrumb hidden-xs">
                            <li><Link to="/"><i className="pe-7s-home" /> Home</Link></li>
                            <li className="active">Dashboard</li>
                        </ol>
                    </div>
                </section>
                <section className="content">

                    <div className="row">

                        <div className="col-sm-12">
                            <div className="panel panel-bd lobidrag">
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="panel-header">


                                            <div className="col-md-12 col-sm-6 col-xs-12">
                                                <div className="dataTables_length">

                                                    <div className="row">


                                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                                            <input type="text" className="form-control" placeholder="search by reg no, name, contact no or class" onChange={(event) => { handleOnSearchInput(event.target.value) }} />
                                                        </div>

                                                        <div className='col-lg-2 col-md-3 col-sm-6'>
                                                            <select className="form-control" value={classId} required name="class" onChange={classChangeHandler} >
                                                                <option value="">Select Class</option>
                                                                {
                                                                    classes.length ?
                                                                        classes.map((value) => {
                                                                            return (<option key={value.id} value={value.name}>{value.name}</option>);
                                                                        })
                                                                        :
                                                                        <option value="">Not Record Found!</option>
                                                                }
                                                            </select>
                                                        </div>





                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">


                                        {
                                            !loading1 ? (
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Reg No</th>
                                                            <th>Class</th>

                                                            <th>Message</th>
                                                            <th>Date</th>
                                                            <th>Read Status</th>

                                                        </tr>
                                                    </thead>


                                                    <tbody>

                                                        {
                                                            messages.length ?
                                                                messages.map((value, key) => {

                                                                    return (<tr key={key}>
                                                                        <td>{value.student_name}</td>
                                                                        <td>{value.reg_no}</td>
                                                                        <td>{value.class_name}</td>

                                                                        <td>{value.message}</td>
                                                                        <td>{Moment(value.created_at).format("DD-MM-YYYY, h:m:s")}</td>
                                                                        <td>{value.read_status}</td>

                                                                    </tr>);
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan='9'>No record found!</td>
                                                                </tr>
                                                        }

                                                        <tr>
                                                            <td colSpan="9" className='table_bottom_total'>Total {messages.length} records found</td>
                                                        </tr>


                                                    </tbody>




                                                </table>)
                                                :
                                                (<div className='loader' style={{ marginLeft: '12%' }}>
                                                    <Circles
                                                        height="60"
                                                        width="60"
                                                        color="#4fa94d"
                                                        ariaLabel="circles-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={loading1}
                                                    />
                                                </div>)
                                        }




                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default ShowMessages